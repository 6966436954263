<template>
  <div class="activityDialog">
    <el-dialog
      top="3%"
      width="500px"
      :title="dialogTitle"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :before-close="closeEvent"
    >
      <el-form ref="dialogForm" :model="form" label-width="120px">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="权益类型：">
              {{
                form.equityType === "cash"
                  ? "现金（元）"
                  : form.equityType === "cash"
                  ? "循环币（个）"
                  : "公益"
              }}
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="form.equityType === 'pw'">
            <el-form-item label="公益项目名称：">
              {{ form.loveTitle }}
            </el-form-item>
          </el-col>
          <el-col :span="24" v-else>
            <el-form-item label="数量：">
              <el-input v-model="form.checkValue"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEvent()">取 消</el-button>
        <el-button
          type="primary"
          @click="submitData2()"
          v-if="form.equityType === 'pw'"
          >确 定</el-button
        >
        <el-button type="primary" @click="submitData()" v-else>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dialogTitle: "订单核收",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      form: {
        id: "",
        checkValue: "",
      },
    };
  },
  methods: {
    handleAvatarSuccess(res) {
      this.form.imgUrl = res.data;
    },
    handleAvatarSuccess2(res) {
      this.form.hdzp = res.data;
    },
    async submitData() {
      let params = {
        checkValue: parseFloat(this.form.checkValue),
        id: this.form.id,
      };
      if (this.form.equityType === "cash") {
        params.checkValue = parseFloat(params.checkValue) * 100;
      }
      let url = "api/admin/recycle/order/completeOrder";
      this.getAjax(url, params, "post").then(() => {
        this.$message.success("操作成功");
        this.closeEvent();
        this.$emit("search");
      });
    },
    submitData2() {
      //公益一键核收
      this.getAjax(
        "api/admin/recycle/order/quickCompleteOrder",
        {
          id: this.form.id,
        },
        "post"
      ).then((res) => {
        this.$message.success("操作成功");
        this.closeEvent();
        this.$emit("search");
      });
    },
    closeEvent() {
      this.visible = false;
      this.form = {
        id: "",
        checkValue: "",
      };
    },
    getInfo(item) {
      this.visible = true;
      this.form = item;
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
