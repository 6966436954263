<template>
  <div class="userList">
    <searchBar :formItems="formItems" @search="search" />
    <div class="tableBox">
      <el-table
        :data="tableData"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center' }"
        border
        style="width: 100%"
      >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          width="80"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="createTime" label="订单信息">
          <template slot-scope="scope">
            <div style="text-align: left">订单号码：{{ scope.row.id }}</div>
            <div style="text-align: left">
              下单时间：{{ scope.row.createTime | formatTime("YMDHMS") }}
            </div>
            <div style="text-align: left">
              鞋子总数：{{ scope.row.shoeCount }}
            </div>
            <div style="text-align: left">
              下单渠道：{{ scope.row.orderSource == "wx" ? "微信" : "支付宝" }}
            </div>
            <div style="text-align: left">
              订单备注：{{ scope.row.remark || "无" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="productImg" label="下单人信息">
          <template slot-scope="scope">
            <div style="text-align: left">
              下单人姓名：{{ scope.row.placeName }}
            </div>
            <div style="text-align: left">
              手机号码：{{ scope.row.placePhone }}
            </div>
            <div style="text-align: left">
              获取权益：{{
                scope.row.equityType === "cash" ? "现金" : "循环币"
              }}
              <span v-if="scope.row.equityType === 'cash'"
                >￥{{ scope.row.equityValue | money }}</span
              >
              <span v-else>{{ scope.row.equityValue }}个</span>
            </div>
            <div style="text-align: left">
              实际权益：{{ scope.row.equityCheckValue }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="物流信息">
          <template slot-scope="scope">
            <div style="text-align: left">
              顺丰物流单号：{{ scope.row.sfMailId }}
            </div>
            <div style="text-align: left">
              预约时间：{{ scope.row.takeTime }}
            </div>
            <div style="text-align: left">
              取件地址：{{ scope.row.placeAddress }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="productImg" label="鞋子详情">
          <template slot-scope="scope">
            <div style="width: 200px; text-align: left">
              靴子数量：{{ scope.row.bootsCount }}
            </div>
            <div style="width: 200px; text-align: left">
              休闲鞋数量：{{ scope.row.casualShoesCount }}
            </div>
            <div style="width: 200px; text-align: left">
              皮鞋数量：{{ scope.row.leatherShoesCount }}
            </div>
            <div style="width: 200px; text-align: left">
              凉鞋数量：{{ scope.row.sandalCount }}
            </div>
            <div style="width: 200px; text-align: left">
              运动鞋数量：{{ scope.row.sportShoesCount }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="orderStatus" width="150" label="订单状态">
          <template slot-scope="scope">
            <span
              :class="
                scope.row.orderStatus == '0'
                  ? 'c_yellow'
                  : scope.row.orderStatus == '1'
                  ? 'c_yellow'
                  : scope.row.orderStatus == '2'
                  ? 'c_blue'
                  : scope.row.orderStatus == '3'
                  ? 'c_blue'
                  : scope.row.orderStatus == '4'
                  ? 'c_green'
                  : scope.row.orderStatus == '5'
                  ? 'c_grey'
                  : ''
              "
            >
              {{ scope.row.orderStatus | yuyueStatus }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.orderStatus === '1'"
              type="text"
              size="small"
              @click="cancelOrder(scope.row)"
              >取消订单</el-button
            >
            <el-button
            v-if="scope.row.orderStatus === '3'"
              @click="completeData(scope.row)"
              type="text"
              size="small"
              >手动核收</el-button
            >
            <el-button
              v-if="scope.row.orderStatus === '3'"
              @click="completeData2(scope.row)"
              type="text"
              size="small"
              >一键核收</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fenye">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="page.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <UserDialog ref="dialog" @search="initPage" />
  </div>
</template>

<script>
import searchBar from "@/components/SearchBar/index";
import UserDialog from "./components/dialog.vue";
import { formatTime } from "video.js";
export default {
  components: { searchBar, UserDialog },
  data() {
    return {
      formItems: [
        {
          label: "",
          type: "el-input",
          fieldName: "placeName",
          attrs: {
            placeholder: "下单人姓名",
          },
        },
        {
          label: "",
          type: "el-input",
          fieldName: "placePhone",
          attrs: {
            placeholder: "下单人手机号",
          },
        },
        {
          label: "",
          type: "el-input",
          fieldName: "sfMailId",
          attrs: {
            placeholder: "顺丰物流单号",
          },
        },
        {
          label: "",
          type: "el-select",
          fieldName: "orderStatus",
          data: [
            {
              label: "订单状态",
              value: "",
            },
            {
              label: "预约完成",
              value: "0",
            },
            {
              label: "上门中",
              value: "1",
            },
            {
              label: "运输中",
              value: "2",
            },
            {
              label: "待核单",
              value: "3",
            },
            {
              label: "已完成",
              value: "4",
            },
            {
              label: "已取消",
              value: "5",
            },
          ],
          attrs: {
            placeholder: "订单状态",
          },
        },
      ],
      page: {
        page: 1,
        size: 10,
      },
      params: {},
      total: 0,
      tableData: [],
    };
  },
  methods: {
    indexMethod(index) {
      return index + 1 + (this.page.page - 1) * this.page.size;
    },
    handleSizeChange(size) {
      this.page.size = size;
      this.initPage();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.initPage();
    },
    search(opt) {
      //搜索
      this.params = opt;
      this.initPage();
    },
    initPage() {
      this.getAjax(
        "api/admin/recycle/order/pageList",
        { ...this.params, ...this.page },
        "post"
      ).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    add() {
      this.$refs.dialog.visible = true;
      this.$refs.dialog.initDialog();
    },
    cancelOrder(item) {
      this.$confirm("确定取消订单吗？").then(() => {
        this.getAjax(
          "api/admin/recycle/order/cleanOrder",
          { id: item.id },
          "post"
        ).then((res) => {
          this.$message.success("取消成功");
          this.initPage();
        });
      });
    },
    completeData(item) {
      this.$refs.dialog.getInfo(item);
    },
    completeData2(item) {
      this.$confirm("确定一键核收吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.getAjax(
          "api/admin/recycle/order/quickCompleteOrder",
          {
            id: item.id,
          },
          "post"
        ).then((res) => {
          this.initPage();
        });
      });
    },
  },
  mounted() {
    this.initPage();
  },
};
</script>

<style lang="less">
.tianfulove {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fff;
  .leftBox {
    width: 220px;
    height: 100%;
    background: #545c64;
  }
  .rightBox {
    flex: 1;
    padding: 20px;
    .spInfoBox {
      display: flex;
      .info {
        flex: 1;
        margin-left: 5px;
        text-align: left;
      }
    }
    .fenye {
      text-align: right;
      padding: 15px 10px;
    }
  }
}
</style>
